import React, { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { SuspenseLoading } from '../../Components'
import { Footer } from '../../Components/Footer'
import PageNotFound from '../../Pages/PageNotFound'
import { CurrentUserProvider } from '../../Providers/CurrentUserProvider'
import { ROUTE_NAMES } from './interfaces'
import { routes } from './routes'
import Styles from './styles.module.scss'

const Home = lazy(() => import(/* webpackChunkName: "DashboardPage" */ '../../Pages/Home'))
const StartPage = lazy(() => import(/* webpackChunkName: "StartPage" */ '../../Pages/Start'))
const LessonsPage = lazy(() => import(/* webpackChunkName: "LessonsPage" */ '../../Pages/Lessons'))
const NormalLessonPage = lazy(() => import(/* webpackChunkName: "NormalLessonPage" */ '../../Pages/NormalLesson'))
const LibraryPage = lazy(() => import(/* webpackChunkName: "LibraryPage" */ '../../Pages/Library'))
const DownloadsPage = lazy(() => import(/* webpackChunkName: "DownloadsPage" */ '../../Pages/Downloads'))
const DictionaryPage = lazy(() => import(/* webpackChunkName: "DictionaryPage" */ '../../Pages/Dictionary'))
const RecitationsPage = lazy(() => import(/* webpackChunkName: "RecitationsPage" */ '../../Pages/Recitations'))
const LibraryMediaPage = lazy(() => import(/* webpackChunkName: "LibraryMediaPage" */ '../../Pages/LibraryMedia'))
const PersonalDevelopment = lazy(
  () => import(/* webpackChunkName: "PersonalDevelopment" */ '../../Pages/PersonalDevelopment')
)
const FriendsPage = lazy(() => import(/* webpackChunkName: "FriendsPage" */ '../../Pages/Friends'))
const MessagesPage = lazy(() => import(/* webpackChunkName: "MessagesPage" */ '../../Pages/Messages'))

const Router: React.FC = (): JSX.Element => {
  const renderRoutes = (): JSX.Element => {
    return (
      <React.Fragment>
        <Route path={routes[ROUTE_NAMES.HOME]} element={<Home />} />
        <Route path={routes[ROUTE_NAMES.START_PAGE]} element={<StartPage />} />
        <Route path={routes[ROUTE_NAMES.LESSONS_PAGE]} element={<LessonsPage />} />
        <Route path={routes[ROUTE_NAMES.NORMAL_LESSON_PAGE]} element={<NormalLessonPage />} />
        <Route path={routes[ROUTE_NAMES.LIBRARY_PAGE]} element={<LibraryPage />} />
        <Route path={routes[ROUTE_NAMES.DOWNLOADS_PAGE]} element={<DownloadsPage />} />
        <Route path={routes[ROUTE_NAMES.DICTIONARY_PAGE]} element={<DictionaryPage />} />
        <Route path={routes[ROUTE_NAMES.RECITATIONS_PAGE]} element={<RecitationsPage />} />
        <Route path={routes[ROUTE_NAMES.LIBRARY_MEDIA_PAGE]} element={<LibraryMediaPage />} />
        <Route path={routes[ROUTE_NAMES.PERSONAL_DEVELOPMENT_PAGE]} element={<PersonalDevelopment />} />
        <Route path={routes[ROUTE_NAMES.FRIENDS]} element={<FriendsPage />} />
        <Route path={routes[ROUTE_NAMES.MESSAGES]} element={<MessagesPage />} />
        {/* Keep as the last path! */}
        <Route path="*" element={<PageNotFound />} />
      </React.Fragment>
    )
  }

  return (
    <TransitionGroup className={Styles.appRoutesTransitionGroup}>
      <CSSTransition key={location.pathname} timeout={{ enter: 250, exit: 0 }} classNames="fade">
        <Routes>{renderRoutes()}</Routes>
      </CSSTransition>
    </TransitionGroup>
  )
}

export const AuthorizedLayout: React.FC = (): JSX.Element => {
  return (
    <div className={Styles.authorizedLayout}>
      <CurrentUserProvider>
        <Suspense fallback={<SuspenseLoading />}>
          <Router />
          <Footer />
        </Suspense>
      </CurrentUserProvider>
    </div>
  )
}
