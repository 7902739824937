import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Button: React.FC<IProps> = ({
  id,
  variant = 'primary',
  size = 'big',
  fullWidth,
  loading,
  withBorder = false,
  withShadow = true,
  children,
  className,
  ...otherProps
}): JSX.Element => {
  const classNames = ((): string => {
    const list: string[] = [Styles.button, Styles[size]]
    if (className) list.push(className)
    if (variant) list.push(Styles[variant])
    if (fullWidth) list.push(Styles.fullWidth)
    if (withBorder) list.push(Styles.withBorder)
    if (loading) list.push(Styles.loading)
    if (withShadow) list.push(Styles.withShadow)

    return list.join(' ')
  })()

  return (
    <button id={id} {...otherProps} className={classNames} type="button">
      {children}
    </button>
  )
}
