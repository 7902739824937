export enum ROUTE_NAMES {
  HOME,
  PROFILE_CREATE,
  START_PAGE,
  LESSONS_PAGE,
  INTRODUCTION_LESSON_PAGE,
  USER_CHILD_PROFILE,
  NORMAL_LESSON_PAGE,
  LIBRARY_PAGE,
  DOWNLOADS_PAGE,
  DICTIONARY_PAGE,
  RECITATIONS_PAGE,
  LIBRARY_MEDIA_PAGE,
  PERSONAL_DEVELOPMENT_PAGE,
  FRIENDS,
  MESSAGES,
  PARENT_PROFILES,
  PARENT_PROFILES_CHILD,
  PARENT_PROFILES_CHILD_DETAILS,
  PARENT_PROFILES_CHILD_UNLOCK,
  PARENT_PROFILES_CHILD_FINAL_TEST,
  PARENT_LESSONS,
  PARENT_ACCOUNT,
  FINAL_TEST,
  FINAL_MESSAGE,
  CERTIFICATE,
}

export type TRoutes = {
  [route in ROUTE_NAMES]: string
}
