import React, { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

import {
  // @ts-ignore eslint-disable-next-line import/no-unresolved
  breakpointlg,
  // @ts-ignore eslint-disable-next-line import/no-unresolved
  breakpointmd,
  // @ts-ignore eslint-disable-next-line import/no-unresolved
  breakpointsm,
  // @ts-ignore eslint-disable-next-line import/no-unresolved
  breakpointxl,
  // @ts-ignore eslint-disable-next-line import/no-unresolved
  breakpointxs,
  // eslint-disable-next-line import/no-unresolved
} from '../../Theme/breakpoints.module.scss'
import { IMediaQueriesContext } from './interfaces'

export const MediaQueriesContext = React.createContext<IMediaQueriesContext>({
  isXLScreen: false,
  isXSScreen: false,
  isMDScreen: false,
  isLGScreen: false,
  isSMScreen: false,
})

export const MediaQueriesProvider: React.FC<{ children: React.ReactNode }> = ({ children }): JSX.Element => {
  const isXLScreen = useMediaQuery({ query: `(min-width: ${breakpointxl})` })
  const isXSScreen = useMediaQuery({ query: `(min-width: ${breakpointxs})` })
  const isMDScreen = useMediaQuery({ query: `(min-width: ${breakpointmd})` })
  const isLGScreen = useMediaQuery({ query: `(min-width: ${breakpointlg})` })
  const isSMScreen = useMediaQuery({ query: `(min-width: ${breakpointsm})` })

  const options = useMemo(() => {
    return { isXLScreen, isXSScreen, isMDScreen, isLGScreen, isSMScreen }
  }, [isLGScreen, isMDScreen, isSMScreen, isXLScreen, isXSScreen])

  return <MediaQueriesContext.Provider value={options}>{children}</MediaQueriesContext.Provider>
}
