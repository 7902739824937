import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../Components/FormComponents/Button'
import { Typography } from '../../Components/Typography'
import Styles from './styles.module.scss'

const PageNotFound = (): JSX.Element => {
  const navigate = useNavigate()
  return (
    <div className={Styles.container}>
      <Typography variant="default" component="h3">
        Pagina niet gevonden
      </Typography>

      <Button id="go-back" onClick={(): void => navigate('/')}>
        Ga terug
      </Button>
    </div>
  )
}

export default PageNotFound
