import { ROUTE_NAMES, TRoutes } from './interfaces'

export const routes: TRoutes = {
  [ROUTE_NAMES.HOME]: `/`,
  [ROUTE_NAMES.PROFILE_CREATE]: `/profile/create/:subscriptionId`,
  [ROUTE_NAMES.START_PAGE]: `/start`,
  [ROUTE_NAMES.LESSONS_PAGE]: `/lessons`,
  [ROUTE_NAMES.NORMAL_LESSON_PAGE]: `/lessons/start`,
  [ROUTE_NAMES.INTRODUCTION_LESSON_PAGE]: `/lessons/:userChildId/introduction`,
  [ROUTE_NAMES.LIBRARY_PAGE]: `/library`,
  [ROUTE_NAMES.DOWNLOADS_PAGE]: `/library/downloads`,
  [ROUTE_NAMES.DICTIONARY_PAGE]: `/library/dictionary`,
  [ROUTE_NAMES.RECITATIONS_PAGE]: `/library/recitations`,
  [ROUTE_NAMES.LIBRARY_MEDIA_PAGE]: `/library/media`,
  [ROUTE_NAMES.PERSONAL_DEVELOPMENT_PAGE]: `/library/personal_development`,
  [ROUTE_NAMES.FRIENDS]: `/friends`,
  [ROUTE_NAMES.MESSAGES]: `/messages`,
  [ROUTE_NAMES.USER_CHILD_PROFILE]: `profile/:userChildId`,
  [ROUTE_NAMES.PARENT_PROFILES]: `parent/profiles`,
  [ROUTE_NAMES.PARENT_PROFILES_CHILD]: `parent/profiles/child`,
  [ROUTE_NAMES.PARENT_PROFILES_CHILD_DETAILS]: `parent/profiles/child/details`,
  [ROUTE_NAMES.PARENT_PROFILES_CHILD_UNLOCK]: `parent/profiles/child/unlock`,
  [ROUTE_NAMES.PARENT_PROFILES_CHILD_FINAL_TEST]: `parent/profiles/child/final_test`,
  [ROUTE_NAMES.PARENT_LESSONS]: `parent/lessons`,
  [ROUTE_NAMES.PARENT_ACCOUNT]: `parent/account`,
  [ROUTE_NAMES.FINAL_TEST]: `/test/:userChildId`,
  [ROUTE_NAMES.FINAL_MESSAGE]: `/final_message/:userChildId`,
  [ROUTE_NAMES.CERTIFICATE]: `/certificate/:userChildId`,
}
