import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { i18nextPlugin } from 'translation-check'

import en from '../Locales/en/translation.json'
import nl from '../Locales/nl/translation.json'

export type TLocale = 'en' | 'nl'

export const SUPPORTED_LOCALES: TLocale[] = ['en', 'nl']

const resources = {
  en: {
    translation: en,
    string: 'English',
  },
  nl: {
    translation: nl,
    string: 'Nederlands',
  },
}

// @ts-ignore
i18n
  // The languageDetector plugin makes sure to check `localStorage` first, if that does not contain a language setting
  // it falls back on the browsers default language. When the user changes the language it automatically stores this in
  // `localStorage`. See its repo for more info: https://github.com/i18next/i18next-browser-languageDetector.
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(i18nextPlugin)
  .init({
    compatibilityJSON: 'v4',
    initImmediate: true,
    resources,
    supportedLngs: SUPPORTED_LOCALES,
    fallbackLng: 'nl',
    lng: 'nl', // default language
    debug: false,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
    translationStats: {
      queryStringParam: 'showtranslations',
      sourceLng: 'en',
      targetLngs: ['nl'],
      preserveEmptyStrings: false,
    },
  })
export default i18n
