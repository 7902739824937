import { useAuth0 } from '@auth0/auth0-react'
import React, { lazy, Suspense } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { SuspenseLoading } from '../../Components'
import { ROUTE_NAMES } from './interfaces'
import { routes } from './routes'
import Styles from './styles.module.scss'

const Login = lazy(() => import(/* webpackChunkName: "LoginPage" */ '../../Pages/Login'))

const Router: React.FC = (): JSX.Element => {
  const location = useLocation()
  const { isAuthenticated } = useAuth0()

  return (
    <TransitionGroup className={Styles.appRoutesTransitionGroup}>
      <CSSTransition key={location.pathname} timeout={{ enter: 250, exit: 0 }} className="fade">
        <Suspense fallback={<SuspenseLoading />}>
          <Routes>
            <Route path={routes[ROUTE_NAMES.LOGIN]} element={<Login />} />
            <Route path="*" element={isAuthenticated ? null : <Navigate to="/" />} />
          </Routes>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  )
}

export const UnauthorizedLayout: React.FC = (): JSX.Element => {
  return (
    <div className={Styles.unauthorizedLayout}>
      <div className={Styles.content}>
        <Router />
      </div>
    </div>
  )
}
