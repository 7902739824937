// @ts-ignore
import './index.module.scss'
// eslint-disable-next-line import/no-unresolved
import 'virtual:svg-icons-register'

import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'

// @ts-ignore
ReactDOM.createRoot(document.getElementById('root')).render(<App />)
