import React from 'react'
import { useMatch } from 'react-router-dom'

import Logo from '../../Assets/Images/dai-logo.png'
import { LazyImage } from '../LazyImage'
import Styles from './styles.module.scss'

export const Footer = (): JSX.Element => {
  const isParentPage = Boolean(useMatch({ path: 'parent', end: false }))

  return (
    <div className={Styles.container}>
      <LazyImage containerClassName={Styles.logo} src={Logo} alt="logo" width={240} height={160} />
      {isParentPage && (
        <ul>
          <li>
            <a
              target="_blank"
              href="https://www.islamstudies.nl/algemene-inschrijvingsvoorwaarden-keuze/"
              rel="noreferrer"
            >
              Inschrijvingsvoorwaarden
            </a>
          </li>

          <li>|</li>

          <li>
            <a href="https://www.islamstudies.nl/privacy/" target="_blank" rel="noreferrer">
              Privacy
            </a>
          </li>

          <li>|</li>

          <li>
            <a href="https://www.islamstudies.nl/veelgestelde-vragen//" target="_blank" rel="noreferrer">
              Veelgestelde vragen
            </a>
          </li>

          <li>|</li>

          <li>
            <a href="https://www.islamstudies.nl/contact/" target="_blank" rel="noreferrer">
              Contact
            </a>
          </li>
        </ul>
      )}

      <span>{`Copyright © Koran voor Kids | Instituut voor Islamstudies ${new Date().getFullYear()}`}</span>
    </div>
  )
}
