import './Lib/i18n'
import 'react-simple-flex-grid/lib/main.css'

import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { AuthorizedLayout } from './Layouts'
import { MediaQueriesProvider } from './Providers/MediaQueriesProvider'

export const Wrapper: React.FC = (): JSX.Element => {
  return (
    <MediaQueriesProvider>
      <Router>
        <AuthorizedLayout />
      </Router>
    </MediaQueriesProvider>
  )
}

const App: React.FC = (): JSX.Element => {
  return (
    <div id="AppWrapper">
      <Wrapper />
    </div>
  )
}
export default App
