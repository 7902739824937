import React, { useCallback, useMemo, useState } from 'react'

import { IAchievementTypesEnum, ICurrentUserContext } from './interfaces'

export const CurrentUserContext = React.createContext<ICurrentUserContext>({
  accomplishedTasks: [],
  addAccomplishedTask: (_: IAchievementTypesEnum) => {},
  avatar: '',
  setAvatar: (_: any) => {},
})

export const CurrentUserProvider: React.FC<{ children: React.ReactNode }> = (props): JSX.Element => {
  const [avatar, setAvatar] = useState<any>()
  const [accomplishedTasks, setAccomplishedTasks] = useState<IAchievementTypesEnum[]>([])
  const { children } = props

  const addAccomplishedTask = useCallback(
    (task: IAchievementTypesEnum): void => {
      setAccomplishedTasks([...accomplishedTasks, task])
    },
    [accomplishedTasks]
  )

  const options = useMemo(() => {
    return {
      accomplishedTasks,
      addAccomplishedTask,
      avatar,
      setAvatar,
    }
  }, [accomplishedTasks, addAccomplishedTask, avatar])

  return <CurrentUserContext.Provider value={options}>{children}</CurrentUserContext.Provider>
}
