import React from 'react'

import loadingGif from '../../Assets/Images/loading.gif'
import { LazyImage } from '../LazyImage'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const SuspenseLoading: React.FC<IProps> = ({ testId }): JSX.Element => {
  return (
    <div data-testid={testId} className={Styles.suspense}>
      <LazyImage src={loadingGif} alt="Loading..." width="128" height="128" />
    </div>
  )
}
